import React from "react"
import Header from "../../components/header/header"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageGallery from "../../components/gallery/gallery-component"
import { mAndAphotos } from "../../components/photos/m-a-photos"
import "../../../styles/global-styles.scss"

const ManijaAndAbulhaq = () => (
  <Layout>
    <Header />
    <SEO
      title="Manija & Abulhaq"
      description="Subtle rustic outdoor backyard wedding located in Etobicoke, Ontario, in a small venue, Château Bordeleau. The wedding consisted of pinks, white and neutral tones. It was a micro wedding for 70 people on a summer day."
    />
    <h1 className="gallery-photos-heading">Manija & Abulhaq</h1>
    <p className="gallery-photos-text-body">
      Subtle rustic outdoor backyard wedding located in Etobicoke, Ontario, in a
      small venue, Château Bordeleau. The wedding consisted of pinks, white and
      neutral tones. It was a micro wedding for 70 people on a summer day.
    </p>
    <ImageGallery photos={mAndAphotos} />
  </Layout>
)

export default ManijaAndAbulhaq
